import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { BrowserStorageService } from '@core/application';
import { Constant } from '@infrastructure/xhelper';

export const isLoggedGuard: CanActivateFn = (route, state) => {
  const platformId = inject(PLATFORM_ID);
  const router = inject(Router);
  if (isPlatformBrowser(platformId)) {
    const storage = inject(BrowserStorageService);
    let isLogged = !!storage.get(Constant.ACCESS_TOKEN) && !!storage.get(Constant.REFRESH_TOKEN);
    if (isLogged) {
      router.navigateByUrl('/');
    }
    return !isLogged;
  }
  return false;
};
