export const environment = {
  production: false,
  apiUrl: 'https://xbe.cozwork.io/',
  defaultTrailTime: 150,
  defaultLanguage: 'en',
  defaultPageNumber: 1,
  defaultPageSize: 5,
  defaultSortBy: 'desc',
  defaultNotificationSort: 'date',
  WEATHER_API_ENDPOINT: 'https://api.weatherapi.com/v1',
  WEATHER_API_KEY: 'cbf9b6d56d96429580922225240705',
  WEATHER_API_DEFAUL_DAYS: 5,
  publicApis: [
    '/register',
    '/login'
  ]
};
