import { Component, forwardRef } from '@angular/core';
import { Context, ServiceAbstract, SignInReadableService } from '@core/application';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Constant, XRegex } from '@infrastructure/xhelper';
import { ViewBase, ViewXEContent } from '@infrastructure/widget';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrl: './sign-in.component.scss',
  providers: [
    Context,
    {
      provide: ServiceAbstract,
      useExisting: forwardRef(() => SignInReadableService),
    },
    {
      provide: ViewXEContent,
      useExisting: forwardRef(() => SignInComponent),
    },
  ],
})
export class SignInComponent extends ViewBase {
  loginForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(XRegex.EMAIL)]),
    password: new FormControl('', [Validators.required]),
    appName: new FormControl(''),
    remember: new FormControl(false),
  });

  isLoginError = false;
  isTogglePassword = false;

  constructor(private router: Router, private translate: TranslateService) {
    super();
  }

  onSignIn() {
    if (this.loginForm.valid) {
      this.isLoginError = false;
      this.context.service.signIn(this.loginForm.value['email'], this.loginForm.value['password'], this.loginForm.value['appName'], this.loginForm.value['remember']).then((res: any) => {
        if (res) {
          this.context.setItemStorage(Constant.AID, res.aid);
          this.context.setItemStorage(Constant.UID, res.uid);
          this.context.setItemStorage(Constant.ACCESS_TOKEN, res.accessToken);
          this.context.setItemStorage(Constant.REFRESH_TOKEN, res.refreshToken);
          this.router.navigateByUrl('/');
        } else {
          this.handleSignInError();
        }
      });
    }
  }

  handleSignInError() {
    this.isLoginError = true;
    this.loginForm.controls['email'].setErrors({ incorrect: true });
    this.loginForm.controls['password'].setErrors({ incorrect: true });
  }

  onTogglePassword() {
    this.isTogglePassword = !this.isTogglePassword;
  }

  refresh(fnd?: any): void {}
  override getCtxServiceType() {
    return SignInReadableService;
  }
}
