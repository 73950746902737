import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { BrowserStorageService } from '@core/application';
import { Constant } from '@infrastructure/xhelper';

export const authGuard: CanActivateFn = (route, state) => {
  const platformId = inject(PLATFORM_ID);
  const router = inject(Router);
  let isLogged = false;
  if (isPlatformBrowser(platformId)) {
    const storage = inject(BrowserStorageService);
    isLogged = !!storage.get(Constant.ACCESS_TOKEN) && !!storage.get(Constant.REFRESH_TOKEN);
    if (!isLogged) {
      router.navigateByUrl('/sign-in');
    }
  }
  return isLogged;
};
