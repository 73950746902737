<!-- BEGIN error -->
<div class="error-page">
  <!-- BEGIN error-page-content -->
  <div class="error-page-content">
    <mat-card class="card mb-5 mx-auto card-wapper">
      <mat-card-content class="card-body">
        <mat-card class="card">
          <div class="error-code">404</div>
          <div class="card-arrow">
            <div class="card-arrow-top-left"></div>
            <div class="card-arrow-top-right"></div>
            <div class="card-arrow-bottom-left"></div>
            <div class="card-arrow-bottom-right"></div>
          </div>
        </mat-card>
      </mat-card-content>


      <div class="card-arrow">
        <div class="card-arrow-top-left"></div>
        <div class="card-arrow-top-right"></div>
        <div class="card-arrow-bottom-left"></div>
        <div class="card-arrow-bottom-right"></div>
      </div>
    </mat-card>
    <h1>Oops!</h1>
    <h3>{{ 'page_404.cant_find_page' | translate }}</h3>
    <hr>
    <p class="mb-1">
      {{ 'page_404.helpful_links' | translate }}
    </p>
    <p class="mb-5">
      <a routerLink="/" class="text-decoration-none text-inverse text-opacity-50">{{ 'page_404.home' | translate }}</a>
      <span class="link-divider"></span>
      <a routerLink="/page/search-results" class="text-decoration-none text-inverse text-opacity-50">{{ 'page_404.search' | translate }}</a>
      <span class="link-divider"></span>
      <a routerLink="/email/inbox" class="text-decoration-none text-inverse text-opacity-50">{{ 'page_404.email' | translate }}</a>
      <span class="link-divider"></span>
      <a routerLink="/calendar" class="text-decoration-none text-inverse text-opacity-50">{{ 'page_404.calendar' | translate }}</a>
      <span class="link-divider"></span>
      <a routerLink="/settings" class="text-decoration-none text-inverse text-opacity-50">{{ 'page_404.settings' | translate }}</a>
      <span class="link-divider"></span>
      <a routerLink="/helper" class="text-decoration-none text-inverse text-opacity-50">{{ 'page_404.helper' | translate }}</a>
    </p>
    <a href="javascript:window.history.back();" class="btn btn-outline-theme px-3 rounded-pill"><i
        class="fa fa-arrow-left me-1 ms-n1"></i> {{ 'page_404.go_back' | translate }}</a>
  </div>
  <!-- END error-page-content -->
</div>
<!-- END error -->
