import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonModule } from '@angular/common';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { HttpClient, provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { authInterceptor } from './authentication/auth.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ViewEmptyComponent } from '@infrastructure/widget';
import { Constant } from '@infrastructure/xhelper';
import { environment } from '../environments/environment';
import { MatIconModule } from '@angular/material/icon';
import { AppThemeService } from './configs/app-theme.service';
import { AppSettings } from './configs/app-settings.service';
import { AppVariablesService } from './configs/app-variables.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent, SignInComponent, PageNotFoundComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatFormFieldModule,
    MatFormField,
    MatInputModule,
    MatCheckboxModule,
    CommonModule,
    MatTableModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    ViewEmptyComponent,
    MatIconModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    provideHttpClient(withFetch()),
    provideClientHydration(),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([authInterceptor])),
    {
      provide: Constant.ENVIRONMENT,
      useFactory: () => {
        return environment;
      },
    },
    {
      provide: Constant.APP_THEME_SERVICE,
      useClass: AppThemeService
    },
    {
      provide: Constant.APP_SETTING_SERVICE,
      useClass: AppSettings
    },
    {
      provide: Constant.APP_VARIABLES_SERVICE,
      useClass: AppVariablesService
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
