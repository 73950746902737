import { Component, Inject, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { AppSettings } from './configs/app-settings.service';
import { AppThemeService } from './configs/app-theme.service';
import { BrowserStorageService } from '@core/application';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Constant } from '@infrastructure/xhelper';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'CELL APP';
  storage: BrowserStorageService | undefined;

  constructor(
    public appSettings: AppSettings,
    private appThemeSerives: AppThemeService,
    private translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.storage = inject(BrowserStorageService);
    }
  }

  ngOnInit() {
    this.appThemeSerives.initTheme();
    this.initI18n();
  }

  initI18n() {
    let currentLang = this.storage?.get(Constant.CURRENT_LANGUAGE);
    if (currentLang) {
      this.translate.setDefaultLang(currentLang);
    } else {
      this.storage?.set(Constant.CURRENT_LANGUAGE, environment.defaultLanguage);
      this.translate.setDefaultLang(environment.defaultLanguage);
    }
  }
}
