import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { authGuard } from './authentication/auth.guard';
import { isLoggedGuard } from './authentication/is-logged.guard';

const routes: Routes = [
  {
    path: 'x',
    loadChildren: () =>
      import('./ui-dispatchers/x/x.module').then((mod) => mod.XModule),
    canActivate: [authGuard]
  },
  {
    path: '',
    loadChildren: () =>
      import('./ui-dispatchers/dispatcher.module').then((mod) => mod.DispatcherModule),
    canActivate: [authGuard]
  },
  {
    path: 'x-business-card',
    loadChildren: () =>
      import('./ui-dispatchers/x-business-card/x-business-card.module').then((mod) => mod.XBusinessCardModule),
    canActivate: [authGuard]
  },
  {
    path: 'sign-in',
    component: SignInComponent,
    canActivate: [isLoggedGuard]
  },
  {
    path: 'page-not-found-404',
    component: PageNotFoundComponent,
  },
  { path: '**', redirectTo: '/page-not-found-404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
