<!-- BEGIN login -->
<cell-view-empty>
  <div id="app" class="app app-without-header app-without-sidebar">
    <div id="content" class="app-content p-0">
      <div class="">
        <div class="login">
          <!-- BEGIN login-content -->
          <div class="login-content">
            <form [formGroup]="loginForm" [ngClass]="{'form-custom-validate': form.submitted}" #form="ngForm">
              <h1 class="text-center">{{ "sign_in.title" | translate }}</h1>
              <div class="text-inverse text-opacity-50 text-center mb-4">{{ "sign_in.sub_title" | translate }}</div>
              <div *ngIf="isLoginError" class="d-flex justify-content-center mb-4 text-danger">
                <h5 class="text-danger d-flex justify-content-center">{{ 'sign_in.sign_in_error' | translate }}</h5>
              </div>
              <div class="mb-3">
                <mat-label class="form-label">{{ "sign_in.email_label" | translate }} <span class="text-danger">*</span></mat-label>
                <mat-form-field class="email-field-login w-100">
                  <input aria-label="email" matInput class="form-control form-control-lg bg-inverse bg-opacity-5" type="email" formControlName="email" />
                </mat-form-field>
                <ng-container *ngIf="loginForm.controls['email'].invalid && form.submitted">
                  <mat-hint *ngIf="loginForm.controls['email'].errors?.['required']" class="mat-hint-invalid-tooltip">{{ "sign_in.email_errors.required" | translate }}</mat-hint>
                  <mat-hint *ngIf="(loginForm.controls['email'].errors?.['email'] || loginForm.controls['email'].errors?.['pattern'])" class="mat-hint-invalid-tooltip">{{ "sign_in.email_errors.invalid_format" | translate }}</mat-hint>
                </ng-container>
              </div>
              <div class="mb-3">
                <div class="d-flex justify-content-between">
                  <mat-label>{{ "sign_in.password_label" | translate }} <span class="text-danger">*</span></mat-label>
                  <mat-label class="ms-auto text-inverse text-decoration-none text-opacity-50">{{ "sign_in.forgot_password_link" | translate }}</mat-label>
                </div>
                <mat-form-field class="password-field-login w-100 d-flex flex-row nowrap align-items-center">
                  <input aria-label="password" matInput class="form-control form-control-lg bg-inverse bg-opacity-5" [type]="isTogglePassword ? 'text' : 'password'" formControlName="password" />
                  <span class="bi toggle-password" matSuffix [ngClass]="isTogglePassword ? 'bi-eye-slash' : 'bi-eye'" (click)="onTogglePassword()"></span>
                </mat-form-field>
                <ng-container *ngIf="loginForm.controls['password'].invalid && form.submitted">
                  <mat-hint *ngIf="loginForm.controls['password'].errors?.['required']" class="mat-hint-invalid-tooltip">{{ "sign_in.password_errors.required" | translate }}</mat-hint>
                </ng-container>
              </div>
              <div class="mb-3">
                <mat-label class="form-label">{{ "sign_in.app_name" | translate }}</mat-label>
                <mat-form-field class="w-100">
                  <input aria-label="appName" matInput class="form-control form-control-lg bg-inverse bg-opacity-5" type="text" formControlName="appName" />
                </mat-form-field>
              </div>
              <div class="mb-3">
                <mat-checkbox color="primary" class="" formControlName="remember">{{ "sign_in.remember" | translate }}</mat-checkbox>
              </div>
              <button type="submit" class="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3" (click)="onSignIn()">{{ "sign_in.signin_button" | translate }}</button>
              <div class="text-center text-inverse text-opacity-50">
                {{ "sign_in.signup_prompt" | translate }}
                <a routerLink="/page/register">{{ "sign_in.signup_link" | translate }}</a>
              </div>
            </form>
          </div>
          <!-- END login-content -->
        </div>
      </div>
    </div>
  </div>
  <!-- END login -->
</cell-view-empty>
